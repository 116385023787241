import React, { useEffect, useState } from 'react';
import abi from '../../abi/stakingContractAbi.json';
import { useConnectWallet } from '@web3-onboard/react';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import bigInt from 'big-integer';

export default function WithdrawAirdropNft(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [{ wallet }] = useConnectWallet();
    const [recoverErcAddr, setRecoverErcAddr] = useState('');
    const [recoverErcAmount, setRecoverErcAmount] = useState('');
    const [myLoader, setMyLoader] = useState('');




    const etherProvider = () => {
        try {
            return new ethers.providers.Web3Provider(wallet?.provider);
        } catch (error) {
            console.log('🚀 ~ StakingPool ~ error', error);
        }
    };
    const etherSigner = etherProvider()?.getSigner();
    const standardtokenContract = new ethers.Contract(
        props?.address?.toLowerCase(),
        abi,
        etherSigner
    );
    const [withdrawAirdropNftAddr, setWithdrawAirdropNftAddr] = useState('');
    const [tokenIds, setTokenIds] = useState('');
    const [tokenIdsIssue, setTokenIdsIssue] = useState('');

    const handleWithdrawAirdropNft = async () => {
        try {
            console.log("tokenIds:", tokenIds)
            let ids = tokenIds.split(',')
            console.log("ids:", ids)
            setIsLoading2(true);
            let airdropNft = await standardtokenContract.withdrawAirdropedNFT(withdrawAirdropNftAddr, ids);
            let waitForTx = await airdropNft.wait();
            if (waitForTx) {
                setIsLoading2(false);
                toast.success('Transaction successful!')
            }
            console.log('🚀 ~ const_claimChargeFee= ~ waitForTx', waitForTx);
        } catch (error) {
            setIsLoading2(false);
            let _stringify = JSON.stringify(error);
            let _parse = JSON.parse(_stringify);
            if (_parse?.error?.data?.message) {
                toast.error(_parse?.error?.data?.message);
            }
        }
    };
    const handleWithdrawCollectionNFTIfFoundIssue = async () => {
        try {
            setIsLoading(true);
            let ids = tokenIdsIssue.split(',')
            let withdrawCollectionNFTIfFoundIssue = await standardtokenContract
                .withdrawCollectionNFTIfFoundIssue(ids)

            let waitFortx = await withdrawCollectionNFTIfFoundIssue.wait();
            if (waitFortx) {
                setIsLoading(false);
                toast.success('Transaction successful!')

            }
        } catch (error) {
            let _stringify = JSON.stringify(error);
            let _parse = JSON.parse(_stringify);
            if (_parse?.error?.data?.message) {
                toast.error(_parse?.error?.data?.message);
            }
            console.log('🚀 ~ const_setChargePayee= ~ error', error);
            setIsLoading(false);

        }
    };



    const hanldeRecoverErc20 = async () => {
        try {
            setMyLoader('recoverErc20');

            let _amount = bigInt(recoverErcAmount);
            // console.log('🚀 ~ hanldeRecoverErc20 ~ _amount', _amount);
            let _call = await standardtokenContract.recoverERC20(
                recoverErcAddr,
                _amount?.toString()
            );
            let waitFortx = await _call.wait();
            if (waitFortx) {
                toast.success('Transaction Success!');
                setMyLoader('');
            }
        } catch (error) {
            setMyLoader('');

            console.log('🚀 ~ hanldeRecoverErc20 ~ error', error);
            toast.error('Something went wrong!');
        }
    };
    return (
        <>
            <ToastContainer />
            <div className='container'>
                <h5 className='mx-md-3 mb-3'>Withdraw Airdrop NFT</h5>
                <div className='row '>
                    <div className='col-6  '>
                        <div className='form-group'>
                            <label htmlFor='name'>NFT contract address</label>
                            <input
                                type='text'
                                id='withdrawAirdropNftAddr'
                                name='withdrawAirdropNftAddr'
                                placeholder='Enter contract address'
                                required='required'
                                value={withdrawAirdropNftAddr}
                                onChange={(e) => setWithdrawAirdropNftAddr(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-6  '>
                        <div className='form-group'>
                            <label htmlFor='name'>NFT token id's</label>
                            <input
                                type='text'
                                id='tokenIds'
                                name='tokenIds'
                                placeholder="Enter token id's 3,1,6"
                                required='required'
                                value={tokenIds}
                                onChange={(e) => setTokenIds(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    {isLoading2 ? (
                        <div className='spinner-border' role='status'>
                            <span className='visually-hidden'></span>
                        </div>
                    ) : (
                        <button
                            className='btn btn-primary active'
                            onClick={handleWithdrawAirdropNft}
                        >
                            submit
                        </button>
                    )}
                </div>


                <div className='row mt-3'>
                    <div className="col-6">
                        <h5 className=" pb-0 mb-3">Recover ERC20</h5>
                        <div className="form-group">
                            <label htmlFor="name">Token Address</label>
                            <input
                                type="text"
                                id="ramount"
                                name="ramount"
                                placeholder="Enter Token Address"
                                required="required"
                                value={recoverErcAddr}
                                onChange={(e) => setRecoverErcAddr(e.target.value)}
                                className="my-2"
                            />
                            <label htmlFor="name">Amount</label>
                            <input
                                type="text"
                                id="ramount"
                                name="ramount"
                                placeholder="Enter reward amount with decimal"
                                required="required"
                                value={recoverErcAmount}
                                className="my-2"
                                onChange={(e) => setRecoverErcAmount(e.target.value)}
                            />
                        </div>
                        <div className="col-12 ">
                            {myLoader === 'recoverErc20' ? (
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden"></span>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary active"
                                    onClick={hanldeRecoverErc20}
                                >
                                    submit
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='col-6  '>
                        <h5 className=' mb-3'>Withdraw Collection NFT (Issue)</h5>

                        <div className='form-group'>
                            <label htmlFor='name' className='pb-2'>NFT token id's</label>
                            <input
                                type='text'
                                id='tokenIdsIssue'
                                name='tokenIdsIssue'
                                placeholder="Enter token id's 5,9,8"
                                required='required'
                                value={tokenIdsIssue}
                                onChange={(e) => setTokenIdsIssue(e.target.value)}
                            />
                        </div>
                        <div className='col-12'>
                            {isLoading ? (
                                <div className='spinner-border' role='status'>
                                    <span className='visually-hidden'></span>
                                </div>
                            ) : (
                                <button
                                    className='btn btn-primary active'
                                    onClick={handleWithdrawCollectionNFTIfFoundIssue}
                                >
                                    submit
                                </button>
                            )}
                        </div>
                    </div>


                </div>



                <div className="row">


                </div>
            </div>


        </>
    );
}
