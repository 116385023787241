import React, { useEffect, useState } from 'react';
import { useConnectWallet } from '@web3-onboard/react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Wallet } from '../../onboard/onboard';
import ModalMenu from '../Modal/ModalMenu';
const Header = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const userData = useSelector((state) => state?.state);

  // useEffect(() => {
  //   console.log("userData", userData);
  // }, [userData]);

  useEffect(() => {
    if (wallet) {
      if (
          wallet?.accounts[0]?.address.toLowerCase() ===
          '0x1c968555856C27382e2432E045D9332929dBf6C8'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0xe23Ae8284bC313CA9240cf49eBb0Fa44b7DA9002'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x66bC3025DD6459690eb6558dc6d6312C3C125f5a'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x6802e6E9147803607D213eB6B5A99639D792D9Ae'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x44288d5016eAd4E04a3bF80Aa42689b48CA9a38D'.toLowerCase()
      ) {
        setIsAdmin(true);
      }
    } else {
      setIsAdmin(false);
    }
  }, [wallet]);

  return (
    <>
      <header id="header">
        {/* Navbar */}
        <nav
            data-aos="zoom-out"
            data-aos-delay={800}
            className="navbar gameon-navbar navbar-expand"
        >
          <div className="container header">
            {/* Logo */}
            <Link to="/" className="navbar-brand">
              <img src="/img/timmothy.png" alt="Timmy Finance"/>
            </Link>
            <div className="ml-auto"/>
            {/* Navbar Nav */}
            <ul className="navbar-nav items mx-auto">
              <li className="nav-item">
                {/* <a href="/" className="nav-link"> */}
                <Link to="/" className="navbar-link pt-2">

                  Home{' '}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="mintingDrodown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                  Minting
                </a>
                <ul className="dropdown-menu" aria-labelledby="mintingDrodown">
                  <li>
                    <Link
                        to="https://alpaca.timmy.finance"
                        className="dropdown-item dropdown-item-black"
                    >
                      Alpaca (Minted out)
                    </Link>
                  </li>
                  <li>
                    <a
                        href='https://scrap.timmy.finance'
                        className='dropdown-item dropdown-item-black'
                    >
                      scrap monsters
                    </a>
                  </li>
                </ul>
              </li>
              {isAdmin && (
                  <li className="nav-item">
                  <span className="nav-link">
                    <Link to={'/admin'}>Admin</Link>
                  </span>
                  </li>
              )}
            </ul>

            {/* <ul className="navbar-nav icons">
              <li className="nav-item">
                <Link
                  to="#"
                  className="nav-link"
                  data-toggle="modal"
                  data-target="#search"
                >
                  <i className="icon-magnifier" />
                </Link>
              </li>
            </ul> */}
            <ul className="navbar-nav toggle">
              <li className="nav-item">
                <Link
                    to="#"
                    className="nav-link"
                    data-toggle="modal"
                    data-target="#menu"
                >
                  <i className="icon-menu m-0"/>
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav action">
              <Wallet/>
            </ul>
          </div>
        </nav>
      </header>
    </>

  );
};

export default Header;
