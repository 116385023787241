import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import abi from '../../abi/stakingContractAbi.json';
import { convertBase64 } from '../common/utils';
import { useMutation } from '@apollo/client';
import { createCollection } from '../../graphql/collections/collectionMutations';
import { useEthers } from '@usedapp/core';
import { useNavigate } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useConnectWallet } from '@web3-onboard/react';
import axios from 'axios';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import tokenAbi from '../../abi/tokenAbi.json'
import bigInt from 'big-integer';

export default function Reward(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [rewardAmount, setRewardAmount] = useState('');
  const [rewardDuration, setRewardDuration] = useState('');
  const [poolAmount, setPoolAmount] = useState('0')

  const etherProvider = () => {
    try {
      return new ethers.providers.Web3Provider(wallet?.provider);
    } catch (error) {
      console.log('🚀 ~ StakingPool ~ error', error);
    }
  };
  const etherSigner = etherProvider()?.getSigner();
  const stakingContract = new ethers.Contract(
    props?.address.toLowerCase(),
    abi,
    etherSigner
  );


  console.log('🚀 ~ Fee ~ etherContractInst', stakingContract);

  const _setRewardAmount = async () => {
    //update approval functionality 
    try {
      if (!rewardAmount) {
        toast.error('Enter amount first!');
        return null;
      }

      console.log("stakingContract:", stakingContract)
      const token_address = await stakingContract.rewardsToken()
      console.log("token_address:", token_address)
      const tokenContract = new ethers.Contract(
        token_address,
        tokenAbi,
        etherSigner
      );
      console.log("tokenContract:", tokenContract)
      let getDecimal = await tokenContract.decimals()
      console.log("getDecimal:", getDecimal)
      console.log("getDecimal:", getDecimal)

      setIsLoading2(true);
      console.log("stakingContract:", stakingContract.address)
      let val = bigInt(rewardAmount * 10 ** getDecimal)
      console.log("val:", val.toString())
      console.log("tokenContract:", tokenContract)
      console.log("stakingContract?.address:", stakingContract?.address)
      console.log("val.toString():", val.toString())
      let _approve = await tokenContract.approve(stakingContract?.address, val.toString())
      let waitForApprove = await _approve.wait()

      if (waitForApprove) {
        let fee = await stakingContract.setRewardAmount(val.toString());
        let waitFortx = await fee.wait();
        if (waitFortx) {
          setIsLoading2(false);
          toast.success('Transaction successful');
        }
      }

    } catch (error) {
      setIsLoading2(false);
      let _stringify = JSON.stringify(error);
      let _parse = JSON.parse(_stringify);
      if (_parse?.error?.data?.message) {
        toast.error(_parse?.error?.data?.message);
      }
      else{
        toast.error('Something went wrong!')
      }
      console.log('🚀 ~ const_setRewardAmount= ~ error', error);
    }
  };
  const _setRewardsDuration = async () => {
    try {
      if (!rewardDuration) {
        toast.error("Enter reward duration frist!")
        return null
      }
      setIsLoading(true);
      let week = rewardDuration * 604800;
      console.log("week:", week)
      let fee = await stakingContract.setRewardsDuration(week);
      let waitFortx = await fee.wait();
      if (waitFortx) {
        setIsLoading(false);
        toast.success('Transaction successful');
      }
    } catch (error) {
      console.log('🚀 ~ const_setRewardsDuration= ~ error', error);
      setIsLoading(false);
      let _stringify = JSON.stringify(error);
      let _parse = JSON.parse(_stringify);
      if (_parse?.error?.data?.message) {
        toast.error(_parse?.error?.data?.message);
      }
    }
  };
  const _setRewardPoolIfMissed = async () => {
    try {
      if (!poolAmount) {
        toast.error('Enter amount first!');
        return null;
      }

      const token_address = await stakingContract.rewardsToken()
      console.log("token_address:", token_address)
      const tokenContract = new ethers.Contract(
        token_address,
        tokenAbi,
        etherSigner
      );
      console.log("tokenContract:", tokenContract)
      let getDecimal = await tokenContract.decimals()
      console.log("getDecimal:", getDecimal)

      setIsLoading3(true);
      // console.log("stakingContract:", stakingContract.address)
      let val = bigInt(poolAmount * 10 ** getDecimal)
      console.log("val:", val.toString())
      console.log("tokenContract:", tokenContract)
      console.log("stakingContract?.address:", stakingContract?.address)
      console.log("val.toString():", val.toString())



      let fee = await stakingContract.setRewardAmountIfPoolMisedUp(val.toString());
      let waitFortx = await fee.wait();
      if (waitFortx) {
        setIsLoading3(false);
        toast.success('Transaction successful');
      }


    } catch (error) {
      setIsLoading3(false);
      let _stringify = JSON.stringify(error);
      let _parse = JSON.parse(_stringify);
      if (_parse?.error?.data?.message) {
        toast.error(_parse?.error?.data?.message);
      }
      console.log('🚀 ~ const_setRewardAmount= ~ error', error);
    }
  }
  return (
    <>
      <ToastContainer />
      <div className='container'>
        <div className='row '>
          <div className='col-6'>
            <h5 className=''>Set Reward duration</h5>
            <div className='form-group'>
              <label htmlFor='name'>Duration</label>
              <input
                type='text'
                id='rduration'
                name='rduration'
                placeholder='Enter time in week'
                required='required'
                value={rewardDuration}
                onChange={(e) => setRewardDuration(e.target.value)}
              />
              <span style={{ fontSize: '14px' }}>Eample: 2 for 2 week</span>
            </div>
            <div className='col-12 '>
              {isLoading ? (
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'></span>
                </div>
              ) : (
                <button
                  className='btn btn-primary active'
                  onClick={_setRewardsDuration}
                >
                  submit
                </button>
              )}
            </div>
          </div>
          <div className='col-6'>
            <h5 className=''>Set Reward Amount</h5>
            <div className='form-group'>
              <label htmlFor='name'>Amount</label>
              <input
                type='text'
                id='ramount'
                name='ramount'
                placeholder='Enter reward amount'
                required='required'
                value={rewardAmount}
                onChange={(e) => setRewardAmount(e.target.value)}
              />
            </div>
            <div className='col-12 '>
              {isLoading2 ? (
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'></span>
                </div>
              ) : (
                <button
                  className='btn btn-primary active'
                  onClick={_setRewardAmount}
                >
                  submit
                </button>
              )}
            </div>
          </div>

        </div>
        <div className="row d-flex justify-content-end">
          <div className="col-6">
            <h5 className=''>Set Reward amount if pool missed</h5>
            <div className='form-group'>
              <label htmlFor='name'>Amount</label>
              <input
                type='text'
                id='rduration'
                name='rduration'
                placeholder='Enter amount'
                required='required'
                value={poolAmount}
                onChange={(e) => setPoolAmount(e.target.value)}
              />
            </div>
            <div className='col-12 '>
              {isLoading3 ? (
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'></span>
                </div>
              ) : (
                <button
                  className='btn btn-primary active'
                  onClick={_setRewardPoolIfMissed}
                >
                  submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
