import React, {useEffect, useState} from 'react';
import {useEthers, ChainId} from '@usedapp/core';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useConnectWallet, useSetChain} from '@web3-onboard/react';
import MyRouts from './routers/routes';
import {useLazyQuery, useMutation} from '@apollo/client';
import {getUsers} from './graphql/users/userQueries';
import {checkUser} from './graphql/users/userMutations';
import {useDispatch} from 'react-redux';
import {saveWallet} from './redux/action';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ModalMenu from './components/Modal/ModalMenu';
import {networkHashMap} from './components/common/constants';
import Moralis from 'moralis';
import 'bootstrap/dist/js/bootstrap.js';
import backgroundImage from '../src/images/3.jpeg';

function App() {
  const [{wallet, connecting}, connect, disconnect] = useConnectWallet();
  const [{connectedChain, chains}] = useSetChain();
  const {activate, chainId} = useEthers();

  const reduxDispatch = useDispatch();
  const [CheckUser] = useMutation(checkUser);

  useEffect(async () => {
    await Moralis.start({
      apiKey: process.env.REACT_APP_MORALIS_KEY
    });
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        window.location.reload();
      });
    }
  });

  useEffect(() => {
    const checkWalletConnectSession = async () => {
      if (window.sessionStorage.walletconnect) {
        connect({
          autoSelect: {label: 'walletConnect', disableModals: true}
        });
     
      }
    };

    checkWalletConnectSession().then(async () => {
      if (wallet?.accounts[0]?.address) {
        await CheckUser({
          variables: {
            payload: {
              wallet: wallet?.accounts[0]?.address
            }
          },
          onCompleted: (data) => {
            reduxDispatch(
              saveWallet({
                role: data.checkUser.role,
                walletAddress: data.checkUser.wallet,
                wallet: wallet
              })
            );
          }
        });
      }
    });
    if (
      wallet &&
      ChainId &&
      (process.env.REACT_APP_ENVIRONMENT == 'DEV'
        ? ChainId.CronosTestnet
        : ChainId.Cronos) !== networkHashMap[connectedChain?.id]
    ) {
      toast.error(
        process.env.REACT_APP_ENVIRONMENT == 'DEV'
          ? 'Connect to Cronos testnet Chain and refresh page'
          : 'Connect to Cronos Chain and refresh page',
        {
          position: 'bottom-right',
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        }
      );
    }
  }, [wallet?.accounts[0]?.address]);

  const appStyle = {
    backgroundImage: `url(${backgroundImage})`,

    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed', // added this line
  };

  return (
    <div style={appStyle}>
      <Header />
      <ToastContainer />
      <MyRouts />
      <ModalMenu />
      <Footer />
    </div>
  );

  return (
    <div style={appStyle}>
      <Header />
      <ToastContainer />
      <MyRouts />
      <ModalMenu />
      <Footer />
    </div>
  );
}

export default App;
