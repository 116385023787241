import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import abi from '../../abi/stakingContractAbi.json';
import { stakingByteCode } from '../../abi/constants';
import { useNavigate, useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useConnectWallet } from '@web3-onboard/react';
import axios from 'axios';
import { ethers } from 'ethers';
import Fee from './Fee';
import Reward from './Reward';
import Other from './Other';
import { ToastContainer, toast } from 'react-toastify';
import WithdrawAirdropNft from './WithdrawAirdropNft';
import BoostNft from './BoostNft';
import { checkAdmin, developerAddr, superAdmin } from '../../constants';
import Reward2 from './Reward2';
import bigInt from 'big-integer';


const Admin = () => {
  const params = useParams();
  const location = useLocation()
  const boostAddress = '0x000000000000000000000000000000000000dEaD'

  console.log("location:", location)
  console.log("location:", location?.state)

  // console.log('🚀 ~ Admin ~ params', params?.address);
  const [formData, setFormData] = useState({});
  const [newContract, setNewContract] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [{wallet, connecting}, connect, disconnect] = useConnectWallet();
  const userData = useSelector((state) => state?.state);
  const [stakingFees, setStakingFees] = useState(false)

  useEffect(async () => {
    if (newContract) {
      let obj = {
        name: formData.name,
        symbol: formData.symbol,
        description: formData.description,
        contract_address: newContract,
        creator_address: wallet?.accounts[0].address,
        //picture: formData.image,
        token_address: formData.ERC20Address,
        collection_address: formData.collectionAddress,
        boostNftAddress: boostAddress,
      };

      let _createCollection = await axios
          .post(`${process.env.REACT_APP_API_URL}nfts`, obj)
          .then((res, err) => {
            if (err) {
              console.log('🚀 ~ let_createCollection=awaitaxios.post ~ err', err);
              toast.error('Something went wrong!');
              return err;
            }
            // console.log('res', res);
            toast.success('Transaction successful!');
            navigate('/');
          });
      console.log("_createCollection:", _createCollection)
    }
  }, [newContract]);

  useEffect(() => {
    if (wallet) {
      if (
          wallet?.accounts[0]?.address.toLowerCase() ===
          '0x1c968555856C27382e2432E045D9332929dBf6C8'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0xe23Ae8284bC313CA9240cf49eBb0Fa44b7DA9002'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x66bC3025DD6459690eb6558dc6d6312C3C125f5a'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x6802e6E9147803607D213eB6B5A99639D792D9Ae'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x44288d5016eAd4E04a3bF80Aa42689b48CA9a38D'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x44288d5016eAd4E04a3bF80Aa42689b48CA9a38D'.toLowerCase()
      ) {
        console.log('🚀 ~ useEffect ~ admin 1 isnotadmin', wallet?.accounts[0]?.address.toLowerCase());
        if (params?.address) {
          navigate(`/admin/${params?.address}`);

        } else {

          navigate('/admin');
        }

      } else {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [wallet]);


  const submitHandler = async (e) => {
    try {


      e.preventDefault();
      // console.log("formData:", formData)
      // debugger;

      const etherProvider = () => {
        try {
          return new ethers.providers.Web3Provider(wallet?.provider);
        } catch (error) {
          console.log('🚀 ~ StakingPool ~ error', error);
        }
      };
      const etherSigner = etherProvider()?.getSigner();
      // console.log("etherSigner:", etherSigner)

      // let tx=etherProvider().sendTransaction()
      setIsLoading(true);
      // let _amount = stakingFees ? 1 : 0
      // const transaction = {
      //   to: superAdmin,
      //   value: bigInt(_amount * 10 ** 18).toString()
      // };
      // let sendAmountToAdmin = await etherSigner.sendTransaction(transaction)
      // let wait = await sendAmountToAdmin.wait()
      // return

      //if (wait) {
      const factory = new ethers.ContractFactory(
          abi,
          '0x' + stakingByteCode,
          etherSigner
      );
      console.log("factory:", factory)

      // let _getEther= await et

      let _arguments = [
        formData.ERC20Address,
        formData.collectionAddress,
        boostAddress,
        stakingFees
      ];
      // console.log("_arguments:", _arguments)
      const contract = await factory
          .deploy(..._arguments)
          .then(function (newContractInstance) {
            // console.log('🚀 ~ newContractInstance', newContractInstance?.address);
            if (!newContractInstance?.address) {
              // console.log(newContractInstance?.addresse);
              return;
            }
            console.log(
                'Deployed Contract Address : ',
                newContractInstance?.address
            );

            setNewContract(newContractInstance?.address);
            setIsLoading(false);
          })
          .catch(function (error) {
            console.log('🚀 ~ error', error);
            toast.error('Something went wrong!');
            setIsLoading(false);
          });
      //}

    } catch (error) {
      setIsLoading(false)
      console.log("error:", error)
      let _stringify = JSON.stringify(error);
      let _parse = JSON.parse(_stringify);
      console.log("_parse:", _parse)

      if (_parse?.error?.data?.message) {
        toast.error(_parse?.error?.data?.message);
      }
      if (_parse?.data?.message) {
        toast.error(_parse?.data?.message);

      }

    }
  };


  const inputChangeHandler = ({target: {name, value}}) => {
    // console.log("name:", name)
    // console.log("value:", value)
    // console.log(stakingFees, "staling fees")
    formData[name] = value.trim();
    let newObj = {...formData};
    setFormData(newObj);
  };

  return (


      <section>
        <ToastContainer/>
        <div className='container-fluid section'>
          <div className='row'>
            {/* menu section start */}
            <div className='col-12 col-lg-2 d-flex justify-content-center '>
              <ul
                  className='nav nav-tabs d-block border-0'
                  id='myTab'
                  role='tablist'
              >
                {params?.address ? (
                    <>
                      <li className='nav-item mb-3' role='presentation'>
                        <button
                            className='nav-link active mb-1'
                            id='home-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#home'
                            type='button'
                            role='tab'
                            aria-controls='home'
                            aria-selected='true'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid green',
                              color: 'white',
                              width: '210px',
                            }}
                        >
                          Home
                        </button>
                      </li>
                      <li className='nav-item mb-3' role='presentation'>
                        <button
                            className='nav-link mb-1'
                            id='allVault-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#allVault'
                            type='button'
                            role='tab'
                            aria-controls='allVault'
                            aria-selected='false'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid green',
                              color: 'white',
                              width: '210px',
                            }}
                        >
                          Fee
                        </button>
                      </li>

                      <li className='nav-item mb-3' role='presentation'>
                        <button
                            className='nav-link mb-1'
                            id='reward-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#reward'
                            type='button'
                            role='tab'
                            aria-controls='reward'
                            aria-selected='false'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid green',
                              color: 'white',
                              width: '210px',
                            }}
                        >
                          Reward
                        </button>
                      </li>
                      <li className='nav-item mb-3' role='presentation'>
                        <button
                            className='nav-link mb-1'
                            id='reward2-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#reward2'
                            type='button'
                            role='tab'
                            aria-controls='reward2'
                            aria-selected='false'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid green',
                              color: 'white',
                              width: '210px',
                            }}
                        >
                          Contract
                        </button>
                      </li>
                      <li className='nav-item mb-3' role='presentation'>
                        <button
                            className='nav-link mb-1'
                            id='other-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#other'
                            type='button'
                            role='tab'
                            aria-controls='other'
                            aria-selected='false'
                            style={{
                              backgroundColor: 'transparent',
                              border: '1px solid green',
                              color: 'white',
                              width: '210px',
                            }}
                        >
                          Other
                        </button>
                      </li>
                      {/*<li className='nav-item mb-3' role='presentation'>*/}
                      {/*  <button*/}
                      {/*      className='nav-link mb-1'*/}
                      {/*      id='boostNft-tab'*/}
                      {/*      data-bs-toggle='tab'*/}
                      {/*      data-bs-target='#boostNft'*/}
                      {/*      type='button'*/}
                      {/*      role='tab'*/}
                      {/*      aria-controls='boostNft'*/}
                      {/*      aria-selected='false'*/}
                      {/*      style={{*/}
                      {/*        backgroundColor: 'transparent',*/}
                      {/*        border: '1px solid green',*/}
                      {/*        color: 'white',*/}
                      {/*        width: '210px',*/}
                      {/*      }}*/}
                      {/*  >*/}
                      {/*    Boost NFT*/}
                      {/*  </button>*/}
                      {/*</li>*/}

                      {checkAdmin(wallet?.accounts[0]?.address?.toLowerCase()) ?
                          <li className='nav-item mb-3' role='presentation'>
                            <button
                                className='nav-link mb-1'
                                id='airdrop-tab'
                                data-bs-toggle='tab'
                                data-bs-target='#airdrop'
                                type='button'
                                role='tab'
                                aria-controls='airdrop'
                                aria-selected='false'
                                style={{
                                  backgroundColor: 'transparent',
                                  border: '1px solid green',
                                  color: 'white',
                                  width: '210px',
                                }}
                            >
                              Platform Admin
                            </button>
                          </li> : ""}

                    </>
                ) : (
                    ''
                )}

                {/* <li className="nav-item mb-3" role="presentation">
                <button
                  className="nav-link mb-1"
                  id="emailUsers-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#emailUsers"
                  type="button"
                  role="tab"
                  aria-controls="emailUsers"
                  aria-selected="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid green",
                    color: "white",
                    width: "210px",
                  }}
                >
                  Subscriber Email
                </button>
              </li> */}
              </ul>
            </div>
            {/* menu section end */}

            {/* content section start  */}
            <div className='col-lg-9'>
              <div className='tab-content' id='myTabContent'>
                <div
                    className='tab-pane fade show active'
                    id='home'
                    role='tabpanel'
                    aria-labelledby='home-tab'
                >
                  <div className='container'>
                    <div className='row stack_row_box'>
                      <div className='container'>
                        <div className='row justify-content-center'>
                          <div className='col-12 col-lg-7'>
                            <div className='apply-form card no-hover'>
                              {/* Intro */}
                              <div className='intro d-flex justify-content-between align-items-end mb-4'>
                                <div className='intro-content'>
                                  <h3 className='mt-3 mb-0'>Admin</h3>
                                </div>
                              </div>
                              <form id='contact-form'>
                                <div className='form-group'>
                                  <label htmlFor='name'>Name</label>
                                  <input
                                      type='text'
                                      id='name'
                                      name='name'
                                      placeholder='boredape'
                                      required='required'
                                      onChange={inputChangeHandler}
                                  />
                                </div>
                                {/* <div className='form-group'>
                                <label htmlFor='symbol'>Symbol</label>
                                <input
                                  type='text'
                                  id='symbol'
                                  name='symbol'
                                  placeholder='XYZ'
                                  required='required'
                                  onChange={inputChangeHandler}
                                />
                              </div> */}
                                <div className='form-group'>
                                  <label htmlFor='description'>Description</label>
                                  <textarea
                                      id='description'
                                      name='description'
                                      placeholder='Description'
                                      cols={30}
                                      rows={3}
                                      required='required'
                                      defaultValue={''}
                                      onChange={inputChangeHandler}
                                  />
                                  <small className='form-text mt-2'>
                                    Briefly describe what you need
                                  </small>
                                </div>

                                <div className='form-group'>
                                  <label htmlFor='ERC20Address'>
                                    Reward Token Address
                                  </label>
                                  <input
                                      type='text'
                                      id='ERC20Address'
                                      name='ERC20Address'
                                      placeholder='ERC20 Token Address'
                                      required='required'
                                      onChange={inputChangeHandler}
                                  />
                                </div>

                                <div className='form-group'>
                                  <label htmlFor='collectionaddress'>
                                    NFT Collection Address
                                  </label>
                                  <input
                                      type='text'
                                      id='collectionAddress'
                                      name='collectionAddress'
                                      placeholder='NFT Collection Address'
                                      required='required'
                                      onChange={inputChangeHandler}
                                  />
                                </div>

                                {/*<div className='form-group'>*/}
                                {/*  <label htmlFor='ratio'>BoostNFt Address</label>*/}
                                {/*  <input*/}
                                {/*      type='text'*/}
                                {/*      id='boostNftAddress'*/}
                                {/*      name='boostNftAddress'*/}
                                {/*      placeholder='BoostNFT Address'*/}
                                {/*      required='required'*/}
                                {/*      onChange={inputChangeHandler}*/}
                                {/*  />*/}
                                {/*</div>*/}
                                {/*<div className='form-group'>*/}
                                {/*  <label htmlFor='ratio'>Picture</label>*/}
                                {/*  <input*/}
                                {/*      type='text'*/}
                                {/*      id='picture'*/}
                                {/*      name='picture'*/}
                                {/*      placeholder='image URL'*/}
                                {/*      required='required'*/}
                                {/*      onChange={inputChangeHandler}*/}
                                {/*  />*/}
                                {/*</div>*/}
                                <div className='form-group d-flex'>
                                  <input
                                      type='checkbox'
                                      id='stakingFees'
                                      name='stakingFees'
                                      placeholder='stakingFees'
                                      // required='required'
                                      value={stakingFees}
                                      onChange={(e) => setStakingFees(e.target.checked)}
                                  />

                                  <label className='pl-2' htmlFor='stakingFees'>Update Staking fees


                                    <span className='px-2'
                                          title='You have the option to update staking fees after deploying the contract ( Default 1 CRONOS )'
                                          style={{cursor: 'pointer'}}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                      <path
                                          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                    </svg>
                                  </span>
                                    <span className='text-secondary'>(Optional)  </span> </label>

                                </div>
                                {/* <div className='form-group'>
                                <label htmlFor='ratio'>Community Address</label>
                                <input
                                  type='text'
                                  id='communityAddress'
                                  name='communityAddress'
                                  placeholder='Community Address'
                                  required='required'
                                  onChange={inputChangeHandler}
                                />
                              </div> */}
                                <button
                                    type='submit'
                                    className='btn btn-bordered active'
                                    onClick={submitHandler}
                                    disabled={
                                        !formData.name ||
                                        !formData.description ||
                                        !formData.ERC20Address ||
                                        !formData.collectionAddress
                                    }
                                >
                                  {isLoading ? (
                                      <div className='col-12 text-center'>
                                        <div
                                            className='spinner-border'
                                            role='status'
                                        >
                                          <span className='visually-hidden'></span>
                                        </div>
                                      </div>
                                  ) : (
                                      'submit'
                                  )}
                                </button>
                              </form>
                              <p className='form-message'/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {params?.address ? (
                    <>
                      <div
                          className='tab-pane fade'
                          id='allVault'
                          role='tabpanel'
                          aria-labelledby='allVault-tab'
                      >
                        {/* <AllVault /> */}
                        <Fee address={params?.address}/>
                      </div>

                      <div
                          className='tab-pane fade'
                          id='reward'
                          role='tabpanel'
                          aria-labelledby='reward-tab'
                      >
                        <Reward address={params?.address}/>
                      </div>

                      <div
                          className='tab-pane fade'
                          id='other'
                          role='tabpanel'
                          aria-labelledby='other-tab'
                      >
                        <Other address={params?.address}/>
                      </div>
                      <div
                          className='tab-pane fade'
                          id='boostNft'
                          role='tabpanel'
                          aria-labelledby='boostNft-tab'
                      >
                        <BoostNft address={params?.address}/>
                      </div>
                      <div
                          className='tab-pane fade'
                          id='reward2'
                          role='tabpanel'
                          aria-labelledby='reward2-tab'
                      >
                        <Reward2 address={params?.address} collection={location?.state}/>
                      </div>
                      <div
                          className='tab-pane fade'
                          id='airdrop'
                          role='tabpanel'
                          aria-labelledby='airdrop-tab'
                      >
                        <WithdrawAirdropNft address={params?.address}/>
                      </div>

                    </>
                ) : (
                    ''
                )}{' '}
                {/* deleteVault */}
              </div>
            </div>
            {/* content section end  */}
          </div>
        </div>
      </section>
  );
};

export default Admin;
