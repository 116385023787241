import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useConnectWallet } from "@web3-onboard/react";
import {Link} from "react-router-dom";

const ModalMenu = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [{ wallet }] = useConnectWallet();

  const userData = useSelector((state) => state?.state);

  useEffect(() => {
    if (wallet) {
      if (
          wallet?.accounts[0]?.address.toLowerCase() ===
          '0x1c968555856C27382e2432E045D9332929dBf6C8'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0xe23Ae8284bC313CA9240cf49eBb0Fa44b7DA9002'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x66bC3025DD6459690eb6558dc6d6312C3C125f5a'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x6802e6E9147803607D213eB6B5A99639D792D9Ae'.toLowerCase() ||
          wallet.accounts[0]?.address.toLowerCase() ===
          '0x44288d5016eAd4E04a3bF80Aa42689b48CA9a38D'.toLowerCase()
      ) {
        setIsAdmin(true);
      }
    } else {
      setIsAdmin(false);
    }
  }, [wallet]);

  return (
    <div id="menu" className="modal fade p-0">
      <div className="modal-dialog dialog-animated">
        <div className="modal-content h-100">
          <div className="modal-header" data-dismiss="modal">
            Menu <i className="far fa-times-circle icon-close" />
          </div>
          <div className="menu modal-body">
            <div className="row w-100">
              <ul className="navbar-nav items mx-auto">
                <li className="nav-item">
                  <a href="https://stake.2timmy.finance" className="nav-link">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a href="https://scrap.timmy.finance/" className="nav-link">
                    Scrap monster mint
                  </a>
                </li>
                <li className="nav-item">
                  <a href="https://alpaca.timmy.finance/" className="nav-link">
                    Alpaca mint (minted out)
                  </a>
                </li>

                {isAdmin && (
                    <li className="nav-item">
                  <span className="nav-link">
                    <Link to={'/admin'}>Admin</Link>
                  </span>
                    </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMenu;



