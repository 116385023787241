import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getNfts } from '../../graphql/users/userQueries';
import NftCard from '../Cards/NftCard';
import { useConnectWallet } from '@web3-onboard/react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { EvmChain } from '@moralisweb3/common-evm-utils';
import Moralis from 'moralis';
import BoostNftCard from '../Cards/BoostNftCard';
import Web3 from 'web3';
import abi from '../../abi/stakingContractAbi.json';
import ReactPaginate from 'react-paginate';
import { chainId } from '../../constants';
import BoostNftCard2 from '../Cards/BoostNftCard2';
import { boostNft2addr } from '../../abi/constants';
import { ethers } from 'ethers';

const NftInventoryModal = ({
  renderData,
  handleStake,
  isLoading,
  setIsLoading,
  handleClose
  // boostNft1Addr
}) => {
  // console.log('🚀 ~ renderData', renderData);
  const [nftData, setNftData] = useState(null);
  const [allNftData, setAllNftData] = useState(null);
  const [boostNftData, setBoostNftData] = useState(null);
  const [boostNft2Data, setBoostNft2Data] = useState(null);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [selectedBoostNft, setSelectedBoostNft] = useState([]);
  const [selectedBoostNft2, setSelectedBoostNft2] = useState([]);
  const [checked, setChecked] = useState(false);
  const [disableNfts, setdisableNfts] = useState([]);
  const [alStaked, setAlstaked] = useState({});
  const [resetNfts, setResetNfts] = useState([])
  const [boostNft2Loading, setboostNft2Loading] = useState(false)
  const [GetNfts] = useLazyQuery(getNfts, {
    fetchPolicy: 'network-only',
  });
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [recentSelected, setRecentSelected] = useState({
    tokenId: null,
    contractAddr: renderData?.boostNftAddress
  })
  const web3 = new Web3(wallet?.provider);
  const contractInstance = new web3.eth.Contract(abi);
  contractInstance.options.address =
    renderData?.contract_address?.toLowerCase();

  const confirmStake = (selectedNfts) => {
    // console.log('stake-------', selectedNfts,selectedBoostnft);
    console.log("reeeeee", recentSelected)
    setIsLoading(true);
    handleStake(selectedNfts, recentSelected);
  };

  const selectMultipleHandler = () => {
    // console.log('check');
    setChecked(!checked);
  };

  useEffect(() => {
    // console.log('disss alstaked',Object.keys (alStaked))
    // console.log('disss nfts',disableNfts)
    // console.log("finallll nfts", [...new Set([...disableNfts,...Object.keys(alStaked)])])
    let _alldisableNfts = [
      ...new Set([...disableNfts, ...Object.keys(alStaked)]),
    ];
    if (checked) {
      const _arr = nftData.filter(
        (item) => !_alldisableNfts.includes(item.token_id)
      );
      // console.log('🚀 ~ useEffect 1234567~ _arr', _arr);

      const arr = _arr.map((item, id) => {
        return item.token_id;
      });
      // console.log('🚀 ~ arr ~ arr', arr);
      setSelectedNfts(arr.slice(0, 40));
    } else {
      setSelectedNfts([]);
    }
  }, [checked]);

  useEffect(async () => {
    runApp();
    // getBoostNft();
    // getBoostNft2()
  }, [renderData]);
  // const getBoostNft = async () => {
  //   try {
  //     const boostNftAddress = boostNft1Addr
  //     // const boostNftAddress = renderData?.boostNftAddress
  //
  //     const response = await Moralis.EvmApi.nft.getWalletNFTs({
  //       address: wallet?.accounts[0]?.address.toLowerCase(),
  //       chain: chainId,
  //       tokenAddresses: [boostNftAddress],
  //       mediaItems: true,
  //     });
  //
  //     // console.log('🚀 ~ runApp ~ response', response.raw);
  //
  //     setIsLoading(false);
  //     let sortNft = response?.raw?.result.sort(
  //       (e, i) => e?.token_id - i.token_id
  //     );
  //     setBoostNftData(sortNft);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };
  // const getBoostNft2 = async () => {
  //   try {
  //     setboostNft2Loading(true)
  //     const boostNft2Address = renderData?.boostNftAddress;
  //
  //
  //     const response = await Moralis.EvmApi.nft.getWalletNFTs({
  //       address: wallet?.accounts[0]?.address.toLowerCase(),
  //       chain: chainId,
  //       tokenAddresses: [boostNft2Address],
  //       mediaItems: true,
  //     });
  //
  //     // console.log('🚀 ~ runApp ~ response', response.raw);
  //
  //     setboostNft2Loading(false);
  //     let sortNft = response?.raw?.result.sort(
  //       (e, i) => e?.token_id - i.token_id
  //     );
  //     setBoostNft2Data(sortNft);
  //   } catch (e) {
  //     setboostNft2Loading(false)
  //     console.error(e);
  //   }
  // };
  const runApp = async () => {
    try {
      const chain = EvmChain.CRONOS;
      // console.log('renderdata runApp', renderData);
      const collectionAddr = renderData?.collection_address;

      const response = await Moralis.EvmApi.nft.getWalletNFTs({
        address: wallet?.accounts[0]?.address.toLowerCase(),
        chain: chainId,
        tokenAddresses: [collectionAddr],
        mediaItems: true,
      });

      // console.log('🚀 ~ runApp ~ response', response.raw);

      setIsLoading(false);
      let sortNft = response?.raw?.result.sort(
        (e, i) => e?.token_id - i.token_id
      );
      // console.log("sortNft:", sortNft)
      let url = `${process.env.REACT_APP_API_URL}stake?collectionAddr=${collectionAddr}&user_address=${wallet?.accounts[0]?.address}`;

      let { data } = await axios.get(url)
      // console.log("data:", data)
      let getId = data[0]?.collections.filter((e) => {
        return (
          e.collection_address?.toLowerCase() == collectionAddr?.toLowerCase()
        );
      });
      // console.log("getId:", getId)
      if (getId) {
        // console.log("sortNft:", sortNft)
        let _removeStakeNfts = sortNft.filter((e) => !getId[0]?.token_id.includes(e?.token_id) ? true : false)
        // console.log("_removeStakeNfts:", _removeStakeNfts)

        setNftData(_removeStakeNfts);

        setAllNftData(response?.raw?.result);
        alreadyStakeNfts(response?.raw?.result);
      }
      else {
        setNftData(sortNft);

        setAllNftData(response?.raw?.result);
        alreadyStakeNfts(response?.raw?.result);
      }


      // let getAll

      console.log("runnnnn")
      console.log("response:", response?.raw?.result)




    } catch (e) {
      console.error(e);
    }
  };


  useEffect(() => {

    if (nftData) {
      handleResetNft(nftData)
    }
    return () => {

    }
  }, [nftData])


  const etherProvider = () => {
    try {
      return new ethers.providers.Web3Provider(wallet?.provider);
    } catch (error) { }
  };

  const etherSigner = etherProvider()?.getSigner();
  const etherContractInst = new ethers.Contract(
    renderData?.contract_address,
    abi,
    etherSigner
  );
  const handleResetNft = async (data) => {


    console.log("111111:", data)
    console.log("111111:", data.length)
    try {


      let arr = []
      for (let p = 0; p < data.length; p++) {

        let _reset = await etherContractInst.alreadyStakedNft(data[p]?.token_id)
        if (_reset) {
          arr.push(data[p]?.token_id)
        }
        console.log("_reset:", _reset)
      }
      console.log("arr:", arr)
      setResetNfts(arr)
    } catch (error) {

    }
    // try {
    //   let arr = []
    //   data?.map(async (item) => {
    //     await contractInstance.methods
    //       .alreadyStakedNft(item?.token_id)
    //       .call()
    //       .then(async (data) => {
    //         if (data) {
    //           console.log("data:", data)
    //           console.log("item.token_id:", item.token_id)
    //           // object[item.token_id] = data;
    //           arr.push(item?.token_id)
    //           // console.log("object[item.token_id] = data;:", object[item.token_id] = data)
    //         }
    //         // console.log("object:", object[])
    //       });
    //     console.log("arr:", arr)
    //     setResetNfts(arr)
    //   })

    // } catch (error) {
    //   console.log("error:", error)

    // }
  };

  const alreadyStakeNfts = async (allNfts) => {
    // console.log('🚀 ~ alreadyStakeNfts ~ allNfts', allNfts);
    let object = {};
    for (let i = 0; i < allNfts.length; i++) {
      const item = allNfts[i];
      let _alreadyStakeNfts = await contractInstance.methods
        .alreadyStakedNft(item?.token_id)
        .call()
        .then((data) => {
          if (data) {
            object[item.token_id] = data;
          }
          // console.log("object-----:", object)
          setAlstaked(object);
        });
    }
  };

  // const loadMoreNfts = () => {
  //   setNftData((prev) => [...prev, ...allNftData.slice(0, 30)]);
  // };

  useEffect(() => {
    // console.log('selectedNfts', selectedNfts);
    // console.log('selectedboostNfts', selectedBoostNft);
    // console.log("selectedBoostNft2:", selectedBoostNft2)
  }, [selectedNfts, selectedBoostNft, selectedBoostNft2]);

  const cardSelectHandle = (tokenId) => {
    if (!selectedNfts.includes(tokenId)) {
      setSelectedNfts([...selectedNfts, tokenId]);
    } else {
      const filter = selectedNfts.filter((id) => {
        return id != tokenId;
      });
      setSelectedNfts(filter);
    }
  };

  // const boostNftcardSelectHandle = (tokenId) => {
  //   if (!selectedBoostNft.includes(tokenId)) {
  //     setSelectedBoostNft([tokenId]);
  //     setRecentSelected({ tokenId: [tokenId], contractAddr: boostNft1Addr })
  //   } else {
  //     const filter = selectedBoostNft.filter((id) => {
  //       return id != tokenId;
  //     });
  //     setSelectedBoostNft(filter);
  //     setRecentSelected({ tokenId: null, contractAddr: renderData?.boostNftAddress })
  //
  //   }
  // };
  // const boostNft2cardSelectHandle = (tokenId) => {
  //   // console.log("tokenId:", tokenId)
  //   if (!selectedBoostNft2.includes(tokenId)) {
  //     setSelectedBoostNft2([tokenId]);
  //     setRecentSelected({ tokenId: [tokenId], contractAddr: renderData?.boostNftAddress })
  //
  //
  //   } else {
  //     const filter = selectedBoostNft2.filter((id) => {
  //       return id != tokenId;
  //     });
  //     setSelectedBoostNft2(filter);
  //     setRecentSelected({ tokenId: null, contractAddr: renderData?.boostNftAddress })
  //
  //   }
  // };

  useEffect(() => {
    if (selectedNfts.length > 40) {
      setSelectedNfts(selectedNfts.slice(0, 40));
    }
  }, [selectedNfts]);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 350, sm: 690, md: '70%' },
    bgcolor: '#16182d',
    // border: "2px solid #000",
    // boxShadow: 24,
    p: '25px 25px 25px',
    borderRadius: '15px',
  };


  const closeHandler = () => {
    handleClose();
  };

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const itemsPerPage = 40;
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = nftData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(nftData?.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % nftData?.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  const handleRemovefromSomeoneStake = async () => {
    console.log("resetNfts:", resetNfts)
    let _remove = await contractInstance.methods['resetAndStake'](resetNfts).send({
      from: wallet.accounts[0].address
    })
    console.log("_remove:", _remove)
  }
  return (
    <>
      <Box sx={style}>
        <p className='text-center ' style={{
          fontWeight: '600',
          fontSize: '22px',
          margin: '0px'
        }}>Stake</p>
        <Divider style={{ marginTop: '2px', backgroundColor: 'white', marginLeft: '42px', marginRight: '42px' }} />
        <Box sx={{ position: 'relative' }}>
          <CloseIcon
            sx={{
              position: 'absolute',
              top: '-53px',
              right: '-20px',
              color: '#ffffff',
              zIndex: '999999',
              cursor: 'pointer',
              fontSize: { xs: '45px', md: '45px' },
              pr: '15px',
            }}
            onClick={closeHandler}
          />
          <Container>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: '10px',
                  maxHeight: { xs: '370px', sm: '400px', md: '430px' },
                  minHeight: '85px',
                  overflowY: 'auto',
                  paddingRight: '15px',
                }}
              >
                {!nftData && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </Grid>
                )}
                {currentItems &&
                  currentItems?.map((item, idx) => {
                    return (
                      <Grid
                        key={`pdt_${idx}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                      // onClick={() => cardSelectHandle(item.tokenId)}
                      >
                        <NftCard
                          item={item}
                          cardSelectHandle={cardSelectHandle}
                          selectedNfts={selectedNfts}
                          renderData={renderData}
                          card='stake'

                        />
                      </Grid>
                    );
                  })}

                {/* <Grid container my={2}>
                  <Grid item md={12} display={'flex'} justifyContent={'center'}>
                    <button
                      className='w-auto btn btn-block text-end'
                      onClick={loadMoreNfts}
                    >
                      Load More
                    </button>
                  </Grid>
                </Grid> */}

                {/* Boost nft  section start */}
                <Grid container>
                  <Grid item md={12} display={'flex'} justifyContent={'end'}>
                    <ReactPaginate
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={20}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}

                      previousLabel="previous"
                      nextLabel="next"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      marginPagesDisplayed={2}
                      containerClassName="pagination justify-content-center"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      // eslint-disable-next-line no-unused-vars
                      // hrefBuilder={(page, pageCount, selected) =>
                      //   page >= 1 && page <= pageCount ? `/page/${page}` : '#'
                      // }
                      hrefAllControls
                      // forcePage={currentPage}
                      onClick={(clickEvent) => {
                        // console.log('onClick', clickEvent);
                        // Return false to prevent standard page change,
                        // return false; // --> Will do nothing.
                        // return a number to choose the next page,
                        // return 4; --> Will go to page 5 (index 4)
                        // return nothing (undefined) to let standard behavior take place.
                      }}
                    />
                  </Grid>
                </Grid>
                {/*<Grid container>*/}
                {/*  <Grid item md={12}>*/}
                {/*    /!* <hr className='myBorder' /> *!/*/}

                {/*    <h4 className='mt-3 mb-0 text-center myBorder'>*/}
                {/*      Boost NFT*/}
                {/*    </h4>*/}
                {/*    <Box sx={{ flexGrow: 1 }}>*/}
                {/*      <Grid*/}
                {/*        container*/}
                {/*        spacing={2}*/}
                {/*        sx={{*/}
                {/*          marginTop: '30px',*/}
                {/*          paddingRight: '15px',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        {!boostNftData && (*/}
                {/*          <Grid item xs={12}>*/}
                {/*            <Box*/}
                {/*              sx={{*/}
                {/*                display: 'flex',*/}
                {/*                alignItems: 'center',*/}
                {/*                justifyContent: 'center',*/}
                {/*              }}*/}
                {/*            >*/}
                {/*              <CircularProgress />*/}
                {/*            </Box>*/}
                {/*          </Grid>*/}
                {/*        )}*/}
                {/*        {boostNftData &&*/}
                {/*          boostNftData.map((item, idx) => {*/}
                {/*            return (*/}
                {/*              <Grid*/}
                {/*                key={`pdt_${idx}`}*/}
                {/*                item*/}
                {/*                xs={12}*/}
                {/*                sm={6}*/}
                {/*                md={4}*/}
                {/*                lg={3}*/}
                {/*              // onClick={() => cardSelectHandle(item.tokenId)}*/}
                {/*              >*/}
                {/*                <BoostNftCard*/}
                {/*                  item={item}*/}
                {/*                  boostNftcardSelectHandle={*/}
                {/*                    boostNftcardSelectHandle*/}
                {/*                  }*/}
                {/*                  selectedBoostNft={selectedBoostNft}*/}
                {/*                  renderData={renderData}*/}
                {/*                  setRecentSelected={setRecentSelected}*/}
                {/*                  boostNftContractAddre={boostNft1Addr}*/}
                {/*                />*/}
                {/*              </Grid>*/}
                {/*            );*/}
                {/*          })}*/}
                {/*      </Grid>*/}
                {/*    </Box>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
                {/*<Grid container>*/}
                {/*  <Grid item md={12}>*/}
                {/*    /!* <hr className='myBorder' /> *!/*/}

                {/*    <h4 className='mt-3 mb-0 text-center myBorder'>*/}
                {/*      Boost NFT 2*/}
                {/*    </h4>*/}
                {/*    <Box sx={{ flexGrow: 1 }}>*/}
                {/*      <Grid*/}
                {/*        container*/}
                {/*        spacing={2}*/}
                {/*        sx={{*/}
                {/*          marginTop: '30px',*/}
                {/*          paddingRight: '15px',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        {!boostNft2Data && (*/}
                {/*          <Grid item xs={12}>*/}
                {/*            <Box*/}
                {/*              sx={{*/}
                {/*                display: 'flex',*/}
                {/*                alignItems: 'center',*/}
                {/*                justifyContent: 'center',*/}
                {/*              }}*/}
                {/*            >*/}
                {/*              <CircularProgress />*/}
                {/*            </Box>*/}
                {/*          </Grid>*/}
                {/*        )}*/}
                {/*        {boostNft2Data &&*/}
                {/*          boostNft2Data.map((item, idx) => {*/}
                {/*            return (*/}
                {/*              <Grid*/}
                {/*                key={`pdt_${idx}`}*/}
                {/*                item*/}
                {/*                xs={12}*/}
                {/*                sm={6}*/}
                {/*                md={4}*/}
                {/*                lg={3}*/}
                {/*              // onClick={() => cardSelectHandle(item.tokenId)}*/}
                {/*              >*/}
                {/*                <BoostNftCard2*/}
                {/*                  item={item}*/}
                {/*                  boostNft2cardSelectHandle={*/}
                {/*                    boostNft2cardSelectHandle*/}
                {/*                  }*/}
                {/*                  selectedBoostNft2={selectedBoostNft2}*/}
                {/*                  renderData={renderData}*/}
                {/*                  boostNftContractAddre={renderData?.boostNftAddress}*/}
                {/*                  setRecentSelected={setRecentSelected}*/}
                {/*                />*/}
                {/*              </Grid>*/}
                {/*            );*/}
                {/*          })}*/}
                {/*      </Grid>*/}
                {/*    </Box>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}

                {/* Boost nft  section start */}

                {selectedNfts.length > 40 && (
                  <Box>Please select no more than 40 NFTs at once!</Box>
                )}
                {nftData && !nftData.length && (
                  <Box
                    style={{
                      display: 'block',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component={'span'}>
                      No Nfts to stake!
                    </Typography>
                  </Box>
                )}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={resetNfts.length ? 8 : 12}>
                  <Box className='stake_popup_btn text-center mt-4'>
                    {nftData && nftData.length > 0 && (
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          component={'input'}
                          type={'checkbox'}
                          onChange={selectMultipleHandler}
                          checked={checked}
                        />
                        <Typography component={'p'} sx={{ ml: '5px' }}>
                          Select max NFTs
                        </Typography>
                      </Box>
                    )}
                    {selectedNfts.length == 40 && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography component={'span'}>
                          Cannot select more than 40 NFTs!
                        </Typography>
                      </Box>
                    )}
                    <button
                      className='btn input-btn mt-2'
                      onClick={() =>
                        confirmStake(selectedNfts, selectedBoostNft, renderData?.boostNftAddress)
                      }
                      disabled={!selectedNfts.length}
                    >
                      {isLoading ? (
                        <div className='col-12 text-center'>
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'></span>
                          </div>
                        </div>
                      ) : (
                        'Stake'
                      )}
                    </button>
                  </Box>
                </Grid>

                {resetNfts.length ? <Grid item xs={4} alignItems={'end'} mt={3}>
                  <Box className='stake_popup_btn text-center mt-4'>

                    <button
                      className='btn input-btn mt-2'
                      onClick={
                        handleRemovefromSomeoneStake
                      }
                    // disabled={!selectedNfts.length}
                    >
                      {isLoading ? (
                        <div className='col-12 text-center'>
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'></span>
                          </div>
                        </div>
                      ) : (
                        'Reset'
                      )}
                    </button>
                  </Box>
                </Grid> : ""}
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default NftInventoryModal;
